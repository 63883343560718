import { EVideoResource, IParseVideo } from '../interfaces';

export function parseVideoURL(url: string): IParseVideo | null {
  if (!url) {
    return null;
  }

  const matchYouTube = url.match(
    /(http:|https:|)?(\/\/)?(player\.|www\.)?(youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|shorts\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/
  );
  const matchVkVideo = url.match(
    /(http:|https:|)?\/\/vk\.com\/(?:(video\?z=(video-\d+_\d+))|(clips\?z=(video-\d+_\d+|clip-\d+_\d+))|(video\/([a-zA-Z0-9-_@]+)\?z=(video-\d+_\d+))|(video\/([a-zA-Z0-9-_@]+)\/clips\?z=(video-\d+_\d+|clip-\d+_\d+))|(video-(\d+_\d+))|(clip-(\d+_\d+)))/
  );

  if (matchYouTube) {
    const video_id = matchYouTube[7];

    if (video_id) {
      return <IParseVideo>{
        resource: EVideoResource.YUOTUBE,
        url: matchYouTube[0],
        id: video_id,
        isShorts: matchYouTube[6] === 'shorts/',
      };
    }
  } else if (matchVkVideo) {
    const videoIdMatch =
      url.match(/video-(\d+_\d+)/) || url.match(/clip-(\d+_\d+)/);

    if (videoIdMatch) {
      return <IParseVideo>{
        resource: EVideoResource.VK_VIDEO,
        url,
        id: videoIdMatch[1],
        isShorts: videoIdMatch[0].startsWith('clip-'),
      };
    }
  } else {
    return null;
  }

  return null;
}
