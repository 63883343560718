import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import * as Raven from 'raven-js';
import { tapResponse } from '@ngrx/operators';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) {}

  private ravenCapture(error: unknown) {
    try {
      Raven.captureException(error);
      (error as { isRavenCaptured?: boolean }).isRavenCaptured = true;
    } catch (e) {
      /**/
    }
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tapResponse(
        () => {
          /**/
        },
        (err) => {
          if (err && err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // const authService = this.injector.get(AuthService);
              // authService.signout();
            } else if (err.status === 404) {
              // if (req && req.headers && req.headers.get(HTTP_PARAM_DENIED_404)) {
              //   return;
              // }
              //
              // const router = this.injector.get(Router);
              // router.navigateByUrl(NOT_FOUND_URL, {skipLocationChange: true});
            } else if (err.status !== 400) {
              if (err.status !== 0) {
                this.ravenCapture(err);
              }

              this.toastr.error('Повторите попытку', 'Сетевая ошибка', {
                timeOut: 3000,
              });
            }
          } else {
            this.ravenCapture(err);

            this.toastr.error(undefined, 'Неизвестная ошибка', {
              timeOut: 3000,
            });
          }
        }
      )
    );
  }
}
