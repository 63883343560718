import { Inject, Injectable } from '@angular/core';
import { IMapOfString } from '../../interfaces';
import { WINDOW } from '../../providers';

interface ILocalStorage extends Storage {
  setItem(key: string, value: string, time?: number): void;
}

@Injectable()
export class StorageService {
  private sessionData: IMapOfString = {};

  private get sessionLength() {
    return Object.keys(this.sessionData).length;
  }

  public session!: Storage;

  private localData: IMapOfString = {};

  private get localLength() {
    return Object.keys(this.localData).length;
  }

  public local!: ILocalStorage;

  constructor(@Inject(WINDOW) private window: Window) {
    this.initSession();
    this.initLocal();
  }

  private initSession() {
    this.session = {
      length: this.sessionLength,
      clear: () => {
        this.sessionData = {};
      },
      getItem: (key: string) => {
        return this.sessionData[key];
      },
      key: (index: number) => {
        return Object.keys(this.sessionData)[index];
      },
      removeItem: (key: string) => {
        delete this.sessionData[key];
      },
      setItem: (key: string, value: string) => {
        this.sessionData[key] = value;
      },
    };
  }

  private initLocal() {
    const CACHE_EXPIRATION_SUFFIX = '-cacheexpiration';

    const getCurrentTime = (): number => {
      // time in minutes
      return Math.floor(new Date().getTime() / (60 * 1000));
    };

    const getExpirationKey = (key: string): string => {
      return key + CACHE_EXPIRATION_SUFFIX;
    };

    const removeExpiredItems = (
      localStorage: Storage,
      currentTime: number
    ): void => {
      Object.keys(localStorage).forEach((key) => {
        if (key.endsWith(CACHE_EXPIRATION_SUFFIX)) {
          checkExpiration(localStorage, key, currentTime);
        }
      });
    };

    const checkExpiration = (
      localStorage: Storage,
      expirationKey: string,
      currentTime: number
    ): boolean => {
      const originalKey = expirationKey.replace(CACHE_EXPIRATION_SUFFIX, '');
      const expValue = localStorage.getItem(expirationKey);
      if (expValue) {
        const expirationTime = parseInt(expValue, 10);
        if (currentTime >= expirationTime) {
          localStorage.removeItem(originalKey);
          localStorage.removeItem(expirationKey);
          return true;
        }
      }
      return false;
    };

    let localStorageWork = false;

    try {
      localStorageWork = typeof this.window.localStorage === 'object';
    } catch (e) {
      localStorageWork = false;
    }

    if (localStorageWork) {
      try {
        this.window.localStorage.setItem('localStorage', '');
        this.window.localStorage.removeItem('localStorage');
      } catch (e) {
        localStorageWork = false;
      }
    }

    if (localStorageWork) {
      // override setItem & getItem
      const _setItem = Storage.prototype.setItem;
      const _getItem = Storage.prototype.getItem;

      const localStorage = this.window.localStorage;

      removeExpiredItems(localStorage, getCurrentTime());

      Storage.prototype.setItem = function (
        key: string,
        value: string,
        time?: number
      ): void {
        try {
          _setItem.call(this, key, value);

          if (time) {
            _setItem.call(
              this,
              getExpirationKey(key),
              (getCurrentTime() + time).toString(10)
            );
          } else {
            localStorage.removeItem(getExpirationKey(key));
          }
        } catch (e) {
          /* empty */
        }
      };

      Storage.prototype.getItem = function (key: string): string | null {
        const value = _getItem.call(this, key);
        const expirationKey = getExpirationKey(key);
        const expValue = _getItem.call(this, getExpirationKey(key));

        if (
          expValue &&
          checkExpiration(localStorage, expirationKey, getCurrentTime())
        ) {
          return null;
        }

        return value;
      };
      // overrides

      this.local = localStorage;
      return;
    }

    this.local = {
      length: this.localLength,
      clear: () => {
        this.localData = {};
      },
      getItem: (key: string) => {
        return this.localData[key];
      },
      key: (index: number) => {
        return Object.keys(this.localData)[index];
      },
      removeItem: (key: string) => {
        delete this.localData[key];
      },
      setItem: (key: string, value: string) => {
        this.localData[key] = value;
      },
    };
  }
}
