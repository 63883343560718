export interface IParseVideo {
  resource: EVideoResource;
  url: string;
  id: string;
  isShorts?: boolean;
}

export enum EVideoResource {
  YUOTUBE = 'YUOTUBE',
  VK_VIDEO = 'VK_VIDEO',
}
